<template>
    <div class="distribution-container">
        <div class="chart" id="chart_left1"></div>
    </div>
</template>
<script>
export default {
    name: "terminal-visit",
    data() {
        return {}
    },
    mounted() {
        this.getEchartLeft1();
    },
    methods: {
        getEchartLeft1() {
            let myChart = echarts.init(document.getElementById('chart_left1'));
            let option = {
                color: ['#EAEA26', '#906BF9', '#FE5656', '#01E17E', '#3DD1F9', '#FFAD05', '#4465fc'],
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c} ({d}%)'
                },
                polar: {},
                angleAxis: {
                    interval: 1,
                    type: 'category',
                    data: [],
                    z: 10,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#0B4A6B',
                            width: 5,
                            type: 'solid'
                        },
                    },
                    axisLabel: {
                        interval: 0,
                        show: true,
                        color: '#0B4A6B',
                        margin: 8,
                        fontSize: 16
                    },
                },
                radiusAxis: {
                    min: 40,
                    max: 120,
                    interval: 20,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#0B3E5E',
                            width: 1,
                            type: 'solid'
                        },
                    },
                    axisLabel: {
                        formatter: '{value} %',
                        show: false,
                        padding: [0, 0, 20, 0],
                        color: '#0B3E5E',
                        fontSize: 16
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#0B3E5E',
                            width: 2,
                            type: "solid"
                        }
                    }
                },
                calculable: true,
                series: [{
                    type: 'pie',
                    radius: ['6%', '10%'],
                    hoverAnimation: false,
                    labelLine: {
                        normal: {
                            show: false,
                            length: 30,
                            length2: 50
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    tooltip: {
                        show: false
                    },
                    data: [{
                        name: '',
                        value: 0,
                        itemStyle: {
                            normal: {
                                color: '#0B4A6B'
                            }
                        }
                    }]
                }, {
                    type: 'pie',
                    radius: ['90%', '95%'],
                    hoverAnimation: false,
                    labelLine: {
                        normal: {
                            show: false,
                            length: 30,
                            length2: 50
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    tooltip: {
                        show: false
                    },
                    data: [{
                        name: '',
                        value: 0,
                        itemStyle: {
                            normal: {
                                color: '#0B4A6B'
                            }
                        }
                    }]
                },{
                    stack: 'a',
                    type: 'pie',
                    radius: ['20%', '80%'],
                    roseType: 'area',
                    zlevel: 10,
                    label: {
                        normal: {
                            show: true,
                            formatter: '{b}',
                            textStyle: {
                                fontSize: 12,
                            },
                            position: 'outside'
                        },
                        emphasis: {
                            show: false
                        }
                    },
                    labelLine: {
                        normal: {
                            show: true,
                            length: 15,
                            length2: 50,
                            lineStyle: {
                                type: 'dotted'
                            }
                        },
                        emphasis: {
                            show: true
                        }
                    },
                    data: [{
                        value: 35,
                        name: 'ios'
                    },{
                        value: 28,
                        name: 'pc'
                    },{
                        value: 23,
                        name: '安卓'
                    }]
                }]
            }
            myChart.setOption(option, true);
            window.addEventListener('resize', () => {
                myChart.resize();
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .distribution-container {
        .chart {
            height: 3rem;
            margin-top: 0.5rem;
        }
    }
</style>