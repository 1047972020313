<template>
  <div class="brand-container">
  	<div class="wrap">
      <header>
        <div class="weather"></div>
        <h2>今日动态</h2>
        <div class="showTime">
          <span class="time">{{ nowTime }}</span>
          <span class="date">
            <span>{{ week }}</span>
            <span>{{ date }}</span>
          </span>
        </div>
      </header>

      <section class="mainbox">
        <div class="item left">
          <div class="panel">
            <h2>今日学习空间访问占比</h2>
            <terminal-visit />
            <div class="panel-footer"></div>
          </div>
          <div class="panel-left2">
            <h2>今日活动趋势</h2>
            <activity-trend />
            <div class="panel-footer"></div>
          </div>
        </div>

        <div class="item center">
          <div class="resume">
            <div class="resume-hd">
              <ul>
                <li>
                  <countTo :startVal='startVal' :endVal='forNum' :duration='6000' separator=""></countTo>
                </li>
                <li>
                  <countTo :startVal='startVal' :endVal='examNum' :duration='6000' separator=""></countTo>
                </li>
              </ul>
            </div>
            <div class="resume-bd">
              <ul>
                <li>今日新增报读人数</li>
                <li>今日参考人数</li>
              </ul>
            </div>
          </div>

          <div class="panel-live">
            <h2>今日直播</h2>
            <today-live />
            <div class="panel-footer"></div>
          </div>
        </div>

        <div class="item right">
          <div class="panel">
            <h2></h2>
            <div class="resume-right">
              <div class="resume-hd">
                <ul>
                  <li>
                    <countTo :startVal='startVal' :endVal='learnNum' :duration='6000' separator=""></countTo>
                  </li>
                </ul>
              </div>
              <div class="resume-bd">
                <ul>
                  <li>今日学习次数</li>
                </ul>
                <h2>
                  <span>周均</span><span style="margin-left:0.71rem;color: red">-13.21% ↓</span>
                  <span style="margin-left: 0.84rem">月均</span><span style="margin-left: 0.71rem;color: green">+10.34% ↑</span>
                </h2>
              </div>
            </div>
            <div class="panel-footer"></div>
          </div>

          <div class="panel-dynamic">
            <h2>实时动态</h2>
            <time-dynamic />
            <div class="panel-footer"></div>
          </div>
        </div>
      </section>
  
    </div>
    
  </div>
</template>

<script>
import '@/assets/js/flexible'
import countTo from 'vue-count-to'
import TerminalVisit from "./c/TerminalVisit.vue";
import ActivityTrend from "./c/ActivityTrend.vue";
import TimeDynamic from "./c/TimeDynamic.vue";
import TodayLive from "./c/TodayLive.vue";
import api from '@/api'

export default {
  name: 'today-dynamic',
  components: {
      TodayLive,
      TimeDynamic,
      TerminalVisit,
      ActivityTrend,
      countTo
  },
  data() {
  	return {
      nowTime: '',
      week: '',
      date: '',
      timer: null,
      startVal: 0,
      forNum: 0,
      examNum: 0,
      learnNum: 85,
      organizationId: ''
  	}
  },
  computed: {
  	
  },
  created() {
      this.initOrganizationByDomain();
  },
  mounted() {
    this.timer = setInterval(() => {
      this.getWeather();
    }, 1000 * 60 * 60)

    this.nowTimes();
    this.getTotayCount();
  },
  methods: {
    timeFormate(timeStamp) { //显示当前时间
      let newDate = new Date(timeStamp);
      let year = newDate.getFullYear();
      let month = newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1;
      let date = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
      let hh = newDate.getHours() < 10 ? '0' + newDate.getHours() : newDate.getHours();
      let mm = newDate.getMinutes() < 10 ? '0' + newDate.getMinutes() : newDate.getMinutes();
      let ss = newDate.getSeconds() < 10 ? '0' + newDate.getSeconds() : newDate.getSeconds();
      let week = newDate.getDay();
      let weeks = ['日', '一', '二', '三', '四', '五', '六'];
      let getWeek = '星期' + weeks[week];
      this.week = getWeek;
      this.date = year + '.' + month + '.' + date;
      this.nowTime = hh + ':' + mm + ':' + ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes)
      this.nowTimes = null;
    },
    async getTotayCount () {
        let res = api.bigdata.getTodayCount({organizationId: localStorage.getItem('organizationId')})
        if (res.code === 200) {
            this.forNum = res.data.forNum;
            this.examNum = res.data.examNum;
        }
    },
      async initOrganizationByDomain() {
        const organizationId = localStorage.getItem('organizationId')
        if (!organizationId) {
            const domain =  window.location.origin || 100000
            let res = await api.protal.getOrganizationByDomain({ domain })
            if (res.code === 200 && res.data) {
                localStorage.setItem('organizationId', res.data.organizationId)
                location.reload();
            }
        }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../assets/css/bigdata-screen/today-dynamic.scss";
</style>
